<template>
  <v-content>
    <div class="textBox py-3">
      <span class="textUpload">
        อัพโหลดรูปสลิปโอนบัญชีหรือหลักฐานการชำระเงินอื่นๆ
      </span>
    </div>
    <v-divider></v-divider>
    <div v-if="url" class="pa-2">
      <v-row class="ma-0 d-flex align-center">
        <v-col :cols="4" class="pa-2">
          <v-img :src="url" contain width="150" height="150" />
        </v-col>
        <v-col :cols="6" class="pa-0">
          <span class="imageName">{{ selectedFile.name }}</span>
        </v-col>
        <v-col :cols="2" class="pa-1 d-flex justify-end">
          <v-icon @click="deleteSlip">delete</v-icon>
        </v-col>
      </v-row>
    </div>
    <v-divider></v-divider>
    <v-container v-if="url">
      <v-form ref="form">
        <v-row align="center" justify="center">
          <v-col :cols="12">
            <div class="datetimeBox d-flex align-center">
              <v-icon class="pa-2">mdi-calendar</v-icon>
              <Datetime
                v-model="date"
                type="datetime"
                placeholder="เวลาที่ชำระเงิน"
                input-style="width:100%;"
              ></Datetime>
            </div>
          </v-col>
        </v-row>
      </v-form>
    </v-container>
    <div class="fixButtom">
      <v-row class="mx-0">
        <div class="btnBox">
          <v-btn x-large tile block color="primary2" dark @click="onButtonClick">
            อัพโหลด
          </v-btn>
          <input
            ref="uploader"
            class="d-none"
            type="file"
            accept="image/*"
            @change="onFileChanged"
          />
        </div>
        <div class="btnBox">
          <v-btn x-large tile block dark color="#2c3239" @click="checkBeforeUpload">
            ยืนยัน
          </v-btn>
        </div>
      </v-row>
    </div>
  </v-content>
</template>

<script>
import { Datetime } from 'vue-datetime'
export default {
  name: 'UploadPayment',
  components: {
    Datetime,
  },
  data: () => {
    return {
      selectedFile: null,
      isSelecting: false,
      url: null,
      // date: new Date().toISOString().substr(0, 10),
      date: null,
      // menu: false,
      modal: false,
      // price: null,
    }
  },
  methods: {
    deleteSlip() {
      this.url = null
    },
    checkBeforeUpload() {
      if (!this.date || this.date === '' || !this.selectedFile) {
        this.$modal.show({
          title: 'กรุณาอัพโหลดสลิป และ แจ้งเวลาที่ชำระเงิน',
          isOne: true,
        })
      } else {
        this.uploadPayment()
      }
    },
    async uploadPayment() {
      const self = this
      try {
        const formData = new FormData()
        formData.append('social_id', self.$store.state.tokenId)
        formData.append('social_channel', 'line')
        formData.append('sale_order', self.$route.query.id)
        formData.append('time_of_payment', self.date)
        formData.append('silp_file_1', self.selectedFile)
        const res = await self.$axios({
          method: 'POST',
          url: '/api/salesorders/confirmPayment',
          data: formData,
          headers: { 'Content-Type': 'multipart/form-data' },
        })
        if (res.data.status === 'success') {
          self.$modal.show({
            title: 'ชำระเงินเรียบร้อย',
            isOne: true,
            onConfirm: () => self.$router.push('/orderstatus'),
            onClose: () => self.$router.push('/orderstatus'),
          })
        } else {
          self.$modal.show({
            title: `ERROR: ${res.data.message}`,
            isOne: true,
          })
        }
      } catch (e) {
        console.log(e)
        self.$modal.show({
          title: `ERROR: ${e}`,
          isOne: true,
        })
      }
    },
    onButtonClick() {
      this.$refs.uploader.click()
    },
    onFileChanged(e) {
      this.selectedFile = e.target.files[0]
      this.url = URL.createObjectURL(this.selectedFile)
    },
  },
}
</script>

<style lang="scss" scoped>
input:focus {
  outline: none;
  border-color: transparent !important;
  background-color: transparent;
}
.datetimeBox {
  background-color: #f7f7f8;
}
// .priceBox {
//   width: 100%;
//   display: inline-block;
//   padding: 10px 10px 10px 0;
// }
.vdatetime {
  width: 100%;
  display: inline-block;
  padding: 10px 10px 10px 0;
}
.imageName {
  display: block;
  color: #000000;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}
.displayBox {
  background-color: #191f28;
  min-height: 250px;
}
.textBox {
  background-color: #fff;
}
.textUpload {
  display: block;
  color: #000000;
  text-align: center;
}
.v-btn {
  width: auto;
  min-width: 0;
  max-width: 100%;
  flex: 1;
}
.btnBox {
  width: auto;
  min-width: 0;
  max-width: 100%;
  flex: 1;
}
</style>
